body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.carousel-indicators {
  top: 0;
  bottom: auto;
}
.carousel-indicators li {
  background-color: rgb(250, 20, 33);
  width: 10px;
  margin-right: 30px;
  margin-left: 30px;
  border-radius: 3px;
  opacity: 0;
}
.carousel-control-next{
  display: none;
}
.carousel-control-prev{
  display: none;
}