._{
    header{
        span{
            color: #000;//#1a5316;
        }
    }
    &_logo{
        height: 75px;
    }
    &_main{
        padding: 0.5rem 1rem;
        :global .badge{
            min-height: 36px;
            line-height: 24px;

            // min-width: 120px;
        }
        :global .title{
            flex: 1;
        }
        :global .value{
            font-size: 16px;
            line-height: 36px;
            font-weight: 500;
            color: rgb(122, 138, 123);
        }
        ul{
            padding: 0;
        }
        li{
            list-style-type: none;
        }
    }
}